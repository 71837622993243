html {
  font-size: 8px;
}

@font-face {
  font-family: 'Kairos Sans Condensed Bold';
  src: local('Kairos Sans Condensed Bold'), url(./fonts/Monotype\ -\ Kairos\ Sans\ W1G\ Cn\ Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Kairos Sans Condensed Medium';
  src: local('Kairos Sans Condensed Medium'), url(./fonts/Monotype\ -\ Kairos\ Sans\ W1G\ Cn\ Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Kairos Sans Condensed';
  src: local('Kairos Sans Condensed'), url(./fonts/Monotype\ -\ Kairos\ Sans\ W1G\ Cn.otf) format('opentype');
}

@font-face {
  font-family: 'Kairos Sans Bold';
  src: local('Kairos Sans Bold'), url(./fonts/Monotype\ -\ Kairos\ Sans\ W1G\ Bold.otf) format('opentype');
}


body {
  background-color: #efefef;
  overflow: hidden;

}

#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  color: #5a5a5a;
  width: 786px;
  height: 1152px;
  margin: 0 auto;
  padding: 30px 60px;
}

* {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-Section {
  flex:1;
  display: flex;
  flex-direction: row;
  border-bottom: 4px solid #FA5000;
  }
.App-Section:last-child {
  border-bottom: none;
}

.Header {
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.Last-Participant {
  color: #787A79;
  font-size: 5rem;
  font-family: 'proxima nova', sans-serif;
  font-weight: 700;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Header-Label {
  font-size: 5rem;
  font-family: "Kairos Sans Condensed Medium", sans-serif;
  color: #FA5000;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Last-Score {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.Score {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: 'Kairos Sans Condensed Bold';
  padding-top:20px;
}

.Score-Value {
  font-size: 160px;
  color: black;
}
.Score-Units {
 font-size: 48px;
 color: black;
}

.Brand {
position: absolute;
top:-40px;
right:-40px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.Powered-By {
  font-size: 14px;
  font-family: 'Kairos Sans Condensed Medium';
}
.HDLogo {
  max-inline-size: 100%;
  block-size: auto;
  max-width: 140px;
}
.Scoreboard-Label {
  font-size: 8rem;
  text-orientation: sideways-right;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-transform: uppercase;
  flex: 1;
  text-align: center;
  font-family: 'Kairos Sans Condensed Medium';
  padding-left: 60px;
  letter-spacing: 1.5rem;
  margin-left: -20px;
}
.Scoreboard-Table {
  max-width: 100%;
  min-width: 0px;
}

.Table {
  display: flex;
  flex-direction: column;

  height:100%;
}
.Table-Body {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  flex:1;
  min-height:380px;
}
.Table-Row {
  display:flex;
  flex:1;
  align-items: center;
  min-height: 76px;
  max-height:76px;
}
.Table-Row:nth-child(even) {
  background-color: #fff;
}

td {
  border: none;
}
.Participant-Rank {
  color:#FA5000;
  font-size: 5rem;
  text-align: center;
  font-family: 'Kairos Sans Bold';
  min-width: 46px;
  margin-right: 30px;

}
.Participant-Name {
  font-size: 5rem;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;
  flex:4;
  margin: 0 2rem;
  min-width: 292px;
  text-transform: uppercase;
  font-family: 'Kairos Sans Condensed';
}

.Participant-Score {
  font-size: 5rem;
  flex:1;
  margin: 0 2rem;
  font-family: 'Kairos Sans Condensed Medium';
  min-width: 95px;

}

.Logo {
  max-inline-size: 100%;
  block-size: auto;
  max-width: 180px;
}

.Login, .Login-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login-Button {
  background-color: #FA5000;
  color: #fff;
  font-size: 2rem;
  font-family: 'Kairos Sans Condensed Medium';
  text-transform: uppercase;
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: none;
  margin-top: 2rem;
  cursor: pointer;
}

.Login-Input {
  border-radius: 1rem;
  border:none;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}

.Login-Error {
  font-size: 1.5rem;
  font-family: 'Kairos Sans Condensed Medium';
  text-transform: uppercase;
  margin-top: 2rem;
}
